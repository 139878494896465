import { configureStore } from '@reduxjs/toolkit'
import serviceOfferingReducer from './serviceOfferingSlice';
import orderReducer from './orderSlice';


const store = configureStore({
    reducer: {
        serviceOffering: serviceOfferingReducer,
        order: orderReducer
    }
})

export default store;