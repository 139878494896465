import { createSlice } from '@reduxjs/toolkit'

export const orderSlice = createSlice({
  name: 'order',
  initialState: [],
  reducers: {
    addOrder: {
      reducer: (state, action) => {
        state.push(action.payload);
      }
    },
    removeOrder: {
      reducer: (state, action) => {
        const itemIndex = state.findIndex(i => i.id === action.payload.id);
        if (itemIndex === -1 || itemIndex === undefined) return;
        state.splice(itemIndex, 1);
      }
    },
    clearOrder: {
      reducer: (state, action) => {
        state.splice(0, state.length);
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const { addOrder, removeOrder, clearOrder } = orderSlice.actions

export default orderSlice.reducer;
