import React from "react";

// Root Include
const Root = React.lazy(() => import("./pages/Home/index"));
const Services = React.lazy(() => import("./pages/Services/index"));
const ServiceDetail = React.lazy(() => import("./pages/Service Detail/index"));
const Cart = React.lazy(() => import("./pages/Cart"));
const Checkout = React.lazy(() => import("./pages/Checkout"));
const ContactUs = React.lazy(() => import("./pages/Contact Us"));
const FAQs = React.lazy(() => import("./pages/FAQs"));
const Security = React.lazy(() => import("./pages/Security"));
const AboutUs = React.lazy(() => import("./pages/About-Us"));
const PrivacyPolicy = React.lazy(() => import("./pages/Privacy Policy"));
const RefundPolicy = React.lazy(() => import("./pages/Refund Policy"));
const Terms = React.lazy(() => import("./pages/Terms"));
const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));

const routes = [
  { path: "/terms", component: Terms, isTopbarDark: false  },
  { path: "/privacy-policy", component: PrivacyPolicy, isTopbarDark: false  },
  { path: "/refund-policy", component: RefundPolicy, isTopbarDark: false  },
  { path: "/faqs", component: FAQs, isTopbarDark: false  },
  { path: "/security", component: Security, isTopbarDark: false  },
  { path: "/contact-us", component: ContactUs, isTopbarDark: false  },
  { path: "/about-us", component: AboutUs, isTopbarDark: false  },
  { path: "/checkout", component: Checkout, isTopbarDark: false  },
  { path: "/cart", component: Cart, isTopbarDark: false  },
  { path: "/service-detail/:id", component: ServiceDetail, isTopbarDark: false },
  { path: "/services", component: Services, isTopbarDark: false },
  { path: "/", component: Root, isTopbarDark: true, exact: true },
  { component: PageError, isWithoutLayout: true, exact: false }
];

export default routes;
