import { createSlice } from '@reduxjs/toolkit'

export const serviceOfferingSlice = createSlice({
  name: 'counter',
  initialState: {
    value: [
      { 
        id: 1, 
        title: "Website", 
        icon: "uil uil-airplay",
        isTopAdvertised: true,
        featureDescription: `Improve your online presence by leveraging the Cloud with us.`,
        description: [
          `The best way to advertise yourself, your products, services, events, and your work is through a website. The website contains common sections like Home, About, Services, Gallery, Shop, Contact Us or any other section you would wish your site to have. We will also integrate your Social Media links if you require.`,
          `This website is fast and modern, globally available, cached and delivered close to your customers from Amazon Web Services Public Cloud. This is also HTTPS secured, Highly Available and Scalable depending on user demand as this is leveraging Serverless Cloud Architecture.`,
          `We can also enable a checkout payment option like PayPal for you to sell your products, services and other products online.`,
          `We are also capable of building the Contact Center for your Business and add chat, phone and video call functionalities in your website.`,
        ],
        services: [
          { 
            id: 51,
            name: 'Basic',
            price: 599,
            description: [
              `Includes purchasing and management of preferred domain as long as available.`,
              `One Business email included using your new domain for example new-user@newdomain.com. `,
              `Modern and fast web application that contains static pages (pages that do not often change) e.g. Home, About, Story, Books, Events, Gallery and Contact Us Page.`,
              `Limited to 3 simple pages`,
              `Limited to 1 free change requests which limits to texts, images, videos, links for the first year.`,
            ]
          },
          {
            id: 52,
            name: 'Essential',
            price: 1599,
            description: [
              `Includes Basic Website features and price.`,
              `Sub domains can be added.`,
              `Two Business emails included.`,
              `Limited to 5 pages.`,
              `Limited to 3 free change requests which limits to texts, images, videos, links for the first year.`,
              `Global caching.`,
            ]
          },
          {
            id: 533,
            name: 'Business',
            price: 2499,
            description: [
              `Includes Essential Website features and price.`,
              `Three Business emails included.`,
              `8 - 10 pages.`,
              `Five free change requests which limits to texts, images, videos, links for the first year.`,
              `Firewall and DDOs Protection`,
              `User login and social media integration.`,
            ]
          },
          {
            id: 53,
            name: 'Enterprise',
            price: 3999,
            description: [
              `Includes Business Website features and price.`,
              `Four Business emails included.`,
              `Amazon Connect Contact Center with Chat to Agent functionality (Exclusive of monthly Amazon Connect operational cost).`,
            ]
          }
        ],
        extraServices: [
          {
            id: 1,
            name: 'Add-On Services',
            description: [],
            services: [
              { 
                id: 54,
                name: 'Ecommerce',
                price: 599,
                description: [
                  `Sell your books, products, services or any of your work from your website directly via Paypal, Stripe or any Payment Gateway.`,
                  `This includes add to cart, checkout and pay option from the site.`,
                  `Payment gateway integration to be assessed depending on availability of payment gateway integration support and availability of Developer API`,
                ]
              },
              { 
                id: 56,
                name: 'Blog Functionality',
                price: 499,
                description: [
                  `Includes Admin page to login for creating and managing Blog content.`,
                  `Requires existing Premium Website`
                ]
              },
              { 
                id: 57,
                name: 'Newsletter and Events',
                price: 499,
                description: [
                  `Includes Newsletter Admin Page to compose email from and select users to send mailing list to.`,
                  `Includes Website Newsletter functionality which stores emails for interested viewers for them to get updates to from your website.`,
                  `Includes functionality for users to unsubscribe to Newsletter, validate subscription request of Newsletter to avoid spam and a Database to store readers contact information.`
                ]
              },
              { 
                id: 58,
                name: 'Amazon Connect Basic Setup',
                price: 499,
                description: [
                  `Enable Amazon Connect for your website or for your business where you will be given a login to Amazon Connect Control Panel to accept calls and do outbound calls towards your customers from and to anywhere and anyone in the world.`,
                  `Amazon Connect Setup includes Business Hours configuration, Claiming a Number, User, Basic Queue, Routing and Contact Flow Setup for Inbound and Outbound Calls.`,
                  `Add and Claim Amazon Connect Phone number to your website.`,
                  `Add Amazon Connect Chat to your website`,
                  `Enable Amazon Connect Customer Relationship Management and Tasks/Tickets.`,
                  `Enable FAQs to your Amazon Connect Chat`,
                ]
              },
              { 
                id: 60,
                name: 'Annual Website Subscription',
                price: 199,
                description: [
                  `Requires existing website.`,
                  `Includes free 3 change requests, renewal of domain and free hosting cost for the upcoming year.`,
                ]
              },
              { 
                id: 65,
                name: 'Annual Email Subscription (Per Email)',
                price: 99,
                description: [
                  `Requires existing email under your domain.`,
                  `Annual email subscription with 50GB of storage.`,
                ]
              },
              { 
                id: 65,
                name: 'Additional Basic Web Page (Per Page)',
                price: 100,
                description: [
                  `Requires existing website.`,
                  `Additional change request, page or new content to an existing website.`,
                ]
              }
            ]
          }
        ] 
      },
      { 
        id: 2,
        title: "Web Hosting and Custom Development",
        icon: "uil uil-rocket",
        isTopAdvertised: true,
        featureDescription: `Web Hosting and Custom Development.`,
        description: [
          `Do you want to focus on building your website but don't know how or where to host them? We offer you what Amazon Web Services offers! Choose from AWS IaaS, PaaS, SaaS/Serverless or Containers and we will build the Infrastructure as a code in Terraform or CloudFormation, SAM, CDK or Serverless Framework. You can focus on your website building and will focus on the hosting and all that comes with it. This also includes setting up your DevOps pipeline and your Security Tests added in the pipeline.` 
        ],
        services: [
          { 
            id: 2301,
            name: 'Infrastructure as a Service Hosting',
            price: 449,
            description: [
              `Choose between different EC2 Instances and we will add only a few monthly setup fee on top of the AWS monthly cost.`,
              `The price is only the setup fee that covers Network, Infrastructure, User Access and Security requirements.`,
              `Anything custom you require, please let us know.`
            ]
          },
          { 
            id: 2302,
            name: 'Platform as a Service Hosting',
            price: 449,
            description: [
              `Choose between different Elastic Beanstalk  and we will add only a few monthly setup fee on top of the AWS monthly cost.`,
              `Anything custom you require, please let us know.`
            ]
          },
          { 
            id: 2303,
            name: 'Serverless Hosting',
            price: 449,
            description: [
              `This is the cheapest hosting option where you only pay what you use.`,
              `Choose among different AWS Serverless services like AWS, Lambda, API Gateway, Step Functions, SNS, SQS, EventBridge and anything you can think of. Our AWS experts will help you leverage and maximise the power of AWS Cloud and its Serverless offering.`,
              `The price is just a placeholder and fee may vary depending on requirements.`
            ]
          },
          { 
            id: 2304,
            name: 'Containerisation Hosting',
            price: 449,
            description: [
              `AWS EKS, ECS with ECR? Choose your pick and we'll collaborate on where you want to be and what you want to do with your Containers.`,
              `The price is just a placeholder and fee may vary depending on requirements.`
            ]
          },
          { 
            id: 2305,
            name: 'DevSecOps Setup',
            price: 599,
            description: [
              `In today's fast-paced digital landscape, ensuring your software is delivered swiftly and securely is paramount. Our DevSecOps as a Service offering integrates security seamlessly into every phase of your development pipeline. By leveraging industry-leading tools like GitHub Actions, AWS CodeCommit, CodeDeploy, CodeBuild, and CodePipeline, we provide a robust solution that enhances efficiency, compliance, and security.`,
              `Automated Workflows, Version Control, Enhanced and Integrated Security Security, Automated Deployments, Rollback Capabilities, Security Integration, Continuous Delivery and Efficiency are some that benefits you can enjoy in this service that we will handle for you.`,
              `The price is just a placeholder and fee may vary depending on requirements.`
            ]
          },
          { 
            id: 2306,
            name: 'Custom Development',
            price: 599,
            description: [
              `Revolutionize Your Business with Serverless Architecture`,
              `In the dynamic world of digital innovation, speed, scalability, and cost-efficiency are crucial. Our custom development services leverage AWS Serverless offerings, empowering you to build and deploy applications with unprecedented agility and reduced operational overhead. By utilizing AWS Lambda, Serverless Framework, API Gateway, EventBridge, SNS, Step Functions, and more, we deliver robust, scalable solutions tailored to your unique business needs.`,
              `The price is just a placeholder and fee may vary depending on requirements.`
            ]
          }
        ],
        extraServices: [
          
        ] 
      },
      { 
        id: 14,
        title: "Domain and SSL",
        icon: "uil uil-shield-check",
        isTopAdvertised: true,
        featureDescription: `Secure your website.`,
        description: [
          `Let us know your ideal domain and we'll buy and manage them for you. No hassle. We will secure your domain and protect your website from threats and Security vulnerabilities. If you have an existing domain, you can migrate to us and we'll take care of the rest.` 
        ],
        services: [
          { 
            id: 2301,
            name: 'Domain name',
            price: 49,
            description: [
              `$49 Annual Fee`,
              `Purchase a domain and lock it for a year.`,
              'Route end users to your site reliably with globally-dispersed Domain Name System (DNS) servers and automatic scaling'
            ]
          },
          { 
            id: 2302,
            name: 'Migrate your domain',
            price: 99,
            description: [
              `Have your existing domain migrate to AWS and we will manage them for you.`
            ]
          },
          { 
            id: 2303,
            name: 'SSL/TLS Certificate',
            price: 99,
            description: [
              `Protect and secure your website`,
              'Protect your internal resources',
              'Securely terminate traffic to your website'
            ]
          },
          { 
            id: 2304,
            name: 'Business Email',
            price: 99,
            description: [
              `$99 Annual Fee`,
              `Secure and encrypted email service`,
              `Includes 50GB of email storage`,
              `Access anywhere`
            ]
          }
        ],
        extraServices: [
        ] 
      },
      { 
        id: 10,
        title: "Contact Center Solution",
        icon: "uil uil-users-alt",
        isTopAdvertised: true,
        featureDescription: `Amazon Basic Connect Setup.`,
        description: [
          `Transform your customer experience (CX) at scale with Amazon Connect, an AI-powered contact center from AWS. Increase agent productivity with generative AI. Measure, track, and improve performance of customer engagements.',
          'Create a seamless omnichannel experience that includes SMS, Chat, Voice and Video.`
        ],
        services: [
          { 
            id: 1001,
            name: 'Amazon Connect Basic Setup',
            price: 499,
            description: [
              `Setup Contact Center solution including Phone Numbers, Business Hours, Agent, Queues, IVR, Inbound and Outbound call.`,
              'Include Chat to Agent feature and add this to your new or existing website.',
              `Enable Amazon Connect for your website or for your business where you will be given a login to Amazon Connect Control Panel to accept calls and do outbound calls towards your customers from and to anywhere and anyone in the world.`,
              `Amazon Connect Setup includes Business Hours configuration, Claiming a Number, User, Basic Queue, Routing and Contact Flow Setup for Inbound and Outbound Calls.`,
              `Add and Claim Amazon Connect Phone number to your website.`,
              `Add Amazon Connect Chat to your website`,
              `Enable Amazon Connect Customer Relationship Management and Tasks/Tickets.`,
              `Enable FAQs to your Amazon Connect Chat`
            ]
          },
          { 
            id: 1002,
            name: 'Migrate Existing Contact Center',
            price: 599,
            description: [
              `Price can be lower or higher depending on what is included in the migration.`,
              'Migration can include Phone Numbers, Agents, Queues, IVR flows and messages,'
            ]
          }
        ],
        extraServices: [
          {
            id: 1,
            name: 'Add-On Services',
            description: [],
            services: [
              { 
                id: 1003,
                name: 'Enable SMS to Agent',
                price: 99,
                description: [
                  `Enable your agents to receive SMS and respond from Amazon Connect to your customers via SMS.`
                ]
              },
              { 
                id: 1004,
                name: 'Enable Facebook Messenger or WhatsApp to Agent Integration',
                price: 299,
                description: [
                  `Enable your agents to receive messages from Facebook Messenger or WhatsApp and respond from Amazon Connect to your customers.`
                ]
              },
              { 
                id: 1005,
                name: `Audio Recording`,
                price: 99,
                description: [
                  `Enable Audio Recording and store for 1 month.`,
                  '$99 per additional month of recording retention.'
                ]
              },
              { 
                id: 1006,
                name: `Customer Profile`,
                price: 99,
                description: [
                  `Provide customer service agents automated experiences with real-time access to up-to-date customer info to personalize each customer interaction.`,
                  'Get a single view of the customer in your contact center',
                  'Amazon Connect Customer Profiles uses machine learning (ML) to detect similar profiles based on similar name, email address, mailing address, and phone number and consolidates them into a unified profile',
                  'Identify key trends and attributes about customers, such as whether they’re a frequent caller or a recently placed an order, based on near real-time customer behavioral data.'
                ]
              },
              { 
                id: 1007,
                name: 'Outbound Campaigns',
                price: 299,
                description: [
                  `Proactively communicate across voice, SMS, and email to quickly serve your customers and improve agent productivity.`,
                  'Automate your campaigns to unlimited leads based on your preferred schedule and agent availability and different custom behaviours.',
                  'Outbound campaigns can handle millions of contacts daily, so you can reach more customers, improving campaign results.',
                  'Easily create campaigns based on customer context and preferred choice of channels. Architect multi-step cross-channel interactions, such as triggering an outbound SMS follow-up based on a call, for a more proactive customer experience.'
                ]
              }
            ]
          }
        ]
      },
      // { 
      //   id: 5, 
      //   title: "Add-on Services", 
      //   icon: "uil uil-home-alt",
      //   isTopAdvertised: false,
      //   featureDescription: `Offers a wide range of additional services to supplement your Business requirements and build on your existing Basic Website.`,
      //   description: [
      //     `Formatting and design are important factors in the readability and overall impact of your book. Make certain that your writer's eye catches the nitty-gritty requirements that you require to ensure a high-quality self-published book. Le Joha Cloud Solutions allows you to customize your publishing package with optional extras to meet your specific needs.`,
      //     `Le Joha Cloud Solutions offers a wide range of additional services to supplement your supported self-publishing experience. Some of these options are included in publishing packages, so make sure to carefully review the details of your service level.`

      //   ] ,
      //   services: [
      //     { 
      //       id: 101,
      //       name: ' Video', 
      //       price: 1999,
      //       description: [
      //         `Share your story to the world, be yourself. This will help you inspire more people, guide them and shine bright like a star.`
      //       ] 
      //     }
      //   ],
      //   extraServices: []
      // }
    ]
  },
  reducers: {},
})

export default serviceOfferingSlice.reducer;
